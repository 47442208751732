@import "../../styles/main";

.CseCollegeCreate_Modal_Title {
    text-align: left;
}

.title {
    @include text(XXL, 700, $black);
}

.CollegeList {
    @include flex-center;
    justify-content: space-between;
    margin-bottom: 10px;
    .ListInfoContainer {
        @include flex-center;
        gap: 16px;
        .CollegeList_titleContainer {
            @include flex-center-column;
            align-items: flex-start;
            .name {
                @include text(L, 700, $black);
            }
            .affiliation {
                @include text(L, 400, $grey);
            }
        }
    }
}

.buttonContainer {
    @include flex-center;
    justify-content: space-between;
    gap: 6px;
    margin-top: 24px;
}

.buttonContainer__isEdit {
    justify-content: center;
}
