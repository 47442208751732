@import "/../styles/main";

.title {
  @include text(XXL, 400, $black);
}

.buttonContainer {
  @include flex-center-wrap;
  gap: 16px;
  padding: 20px;
  align-items: stretch;
}
