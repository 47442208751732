@import "../../styles/main";

.AccordionTitle {
    @include flex-center;
    justify-content: flex-start;
}

.AccordionTitle__white {
    padding-left: 8px;
    gap: 16px;
    .left {
        @include text(L, 600, $grey-two);
    }
    .right {
        @include text(L, 400, $black-two);
    }
}

.AccordionTitle__blue {
    gap: 5px;
    padding-left: 16px;
    .left {
        @include text(L, 700, $blue);
    }
    .right {
        @include text(L, 400, $blue);
    }
}

.AccordionTitle__grey {
    gap: 5px;
    padding-left: 32px;
    .left {
        @include text(L, 700, $grey);
    }
    .right {
        @include text(L, 400, $grey);
    }
}
