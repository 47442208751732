@import "../../styles/main";

.ElectionListElement {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.CollegeList_buttonContainer {
  @include flex-center;
  gap: 10px;
  justify-content: flex-end;
}

.ListInfoContainer {
  @include flex-center;
  gap: 30px;
  justify-content: space-between;
  width: 100%;
}

.CollegeList_buttonContainer_children {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  img {
    padding-bottom: 2px;
  }
}
