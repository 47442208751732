@import "../../styles/main";

.noResults {
  @include flex-center-wrap;
  height: 145px;
  background: $grey-three;
  width: 100%;
}

.noResultText {
  @include text(L, 400, $black);
}
