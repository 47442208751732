.CseSubmitButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 24px;
  position: static;
  min-width: 121px;
  min-height: 57px;
  background: #1d529c;
  margin-top: 5em;
}

.CseStrippingKeyForm {
  padding-bottom: 25px;
}

.CsePasswordInputContainer {
  display: flex;
  align-items: center;
}
