@import "../../styles/main";

.CustomButton {
    max-width: 442px;
    background: $blue-two;
    border-radius: 4px;
    padding: 12px 8px;
    border: none;
    @include flex-center-column;
    @include font-family();
    .title {
        margin: 0;
        @include text(L, 600, $white);
    }
    .text {
        @include text(L, 400, $white);
    }
    cursor: pointer;
    &:hover {
        background: rgba($blue-two, 0.9);
    }
}

.CustomButton__isBig {
    .title {
        @include text(L, bold, $white);
    }
    padding: 20px;
    width: 100%;
    font-weight: 600;
    .icon {
        padding-bottom: 15px;
    }
}

.CustomButton__isWhite {
    background: $grey-four;
    .title {
        @include text(L, bold, $grey);
    }
    &:hover {
        background: rgba($grey-four, 0.9);
    }
}

.CustomButton__disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.6;
    &:hover {
        opacity: 0.5;
    }
}
