@import "../../../styles/main";

.ElectionChoice,
.ElectionLists {
  @include flex-center-column;
  align-items: flex-start;
  padding: 0 20px;
}

.ElectionChoice_Title,
.ElectionLists_Title {
  @include text("XL", 400, $black);
  margin: 0;
}

.ElectionChoice_Name {
  @include text("XL", 700, $black);
}

.ElectionChoice_Vote {
  width: 100%;
  background-color: $grey-three;
  @include flex-center;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
}

.ElectionChoice_CollegeName {
  @include text("L", 700, $black);
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
}

.ElectionChoice_ElectionName {
  @include text("M", 400, $grey);
  text-align: left;
  margin: 0;
}

.ElectionChoice_hasVoted {
  @include text("L", 400, $blue-two);
}

.ElectionChoice_VoteChoiceContainer {
  @include flex-center-column;
  width: 100%;
  gap: 15px;
}

.ElectionLists_CollegeName {
  @include text("L", 700, $black);
  text-align: left;
  margin: 10px 0;
}

.ElectionLists_ElectionName {
  @include text("M", 400, $grey);
  text-align: left;
  margin: 0;
}

.VoteChoice_Name {
  @include text("M", 400, $grey);
  text-align: left;
  margin: 0;
}

.ListPreviewContainer {
  @include flex-center;
  gap: 10px;
  justify-content: flex-start;
  min-width: 200px;
}

.ListInfoContainer {
  @include flex-center;
  gap: 30px;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
}

.CollegeList_buttonContainer,
.AttendanceButtons,
.DepositCertificateButtons {
  @include flex-center;
  gap: 10px;
  justify-content: flex-end;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.VoteReceipt_DetailsContainer {
  @include flex-center-column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
}

.CollegeList_buttonContainer_children {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  img {
    padding-bottom: 2px;
  }
}

.CollegeList_titleContainer {
  @include flex-center-column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  .name {
    @include text(L, 700, $black);
  }
  .affiliation {
    @include text(L, 400, $grey);
  }
}

.CollegeList_Container {
  @include flex-center-column;
  align-items: flex-start;
  gap: 10px;
  margin-top: 30px;
  width: 100%;

  @media (max-width: 768px) {
    gap: 45px;
  }
}

.ElectionListDocuments {
  @include flex-center-column;
  align-items: flex-start;
}

.ElectionListDocuments_title {
  @include text(XL, 700, $black);
  margin-bottom: 10px;
}

.ElectionListDocuments_ActionContainer {
  width: 100%;
  @include flex-center;
  justify-content: space-between;
}
.ElectionListDocuments_container {
  width: 100%;
  @include box-sizing(border-box);
  @include flex-center-column;
  margin-bottom: 20px;
  align-items: flex-start;
  gap: 10px;
  .ElectionListDocuments_subtitle {
    @include text(L, 500, $black);
    margin-bottom: 10px;
    text-align: left;
  }
  .ElectionListDocuments_buttonContainer {
    @include flex-center-wrap;
    @include text(L, 500, $black);
    margin-bottom: 10px;
    text-align: left;
    gap: 15px;
  }
  .ElectionListDocuments_Candidate {
    border-radius: 44px;
    width: 95%;
    padding: 16px;
    margin-bottom: 10px;
    @include flex-center;
    justify-content: space-between;
    background-color: $grey-three;
    @include box-sizing(content-box);
  }

  .ElectionListDocuments_Candidate_Images {
    @include flex-center;
    gap: 10px;
  }
  .ElectionListDocuments_Candidate_Name {
    @include text(L, 700, $black);
  }
}

.spacer {
  margin-bottom: 20px;
}

.VoteExplanations,
.VoteReceipt {
  text-align: left;
  width: 100%;
  @include flex-center-column;
  align-items: flex-start;
  padding: 0 20px;
  .title {
    @include text(XL, 700, $black);
  }
  .time {
    @include text(L, 400, $grey);
  }
}

.CandidateVote {
  border-radius: 44px;
  width: 95%;
  padding: 16px;
  cursor: pointer;
  @include flex-center;
  justify-content: space-between;
  background-color: $grey-three;
  @include box-sizing(content-box);
}

.CandidateVote_Images {
  @include flex-center;
  gap: 10px;
}
.CandidateVote_Name {
  @include text(L, 700, $white);
}

.CandidateVote__selected {
  background-color: $blue-two;
}

.CandidateVote_Name__unselected {
  @include text(L, 700, $black);
  text-decoration: line-through;
}

.CandidateVoteContainer {
  width: 100%;
  @include flex-center-column;
  gap: 5px;
}

.CandidateVote__voteByList {
  cursor: default;
  pointer-events: none;
}

.VoteSummary,
.VoteConfirmation {
  width: 100%;
  @include flex-center-column;
  background-color: $grey-three;
  padding: 26px 8px;
  margin-bottom: 20px;
}

.VoteAttendance {
  width: 100%;
  @include flex-center-column;
  background-color: $grey-three;
  padding: 26px 8px;
  margin-bottom: 20px;
}

.VoteSummary_title {
  @include text(L, 500, $blue-two);
  margin-bottom: 0;
}

.VoteSummary_name {
  text-align: left;
}

.borderSpacer {
  width: 100%;
  margin: 20px 10px;
  border: 1px solid $grey-four;
}

.CsePasswordInputContainer {
  display: flex;
  align-items: center;
}
