@import "../../styles/main";

.CseCandidateForm {
    @include flex-center;
    align-items: flex-start;
    gap: 5px;
    @include mq("tablet-wide") {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
        div {
            max-width: 310px;
            width: 100%;
        }
    }
}

.DeleteButton {
    &:hover {
        content: url("/img/delete-button-selected.svg");
    }
}
