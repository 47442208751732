@import "../../styles/main";

.CandidateVote {
  border-radius: 44px;
  width: 95%;
  padding: 16px;
  cursor: pointer;
  @include flex-center;
  justify-content: space-between;
  background-color: $blue-two;
  @include box-sizing(content-box);
}

.CandidateVote_Images {
  @include flex-center;
  gap: 10px;
  color: white;
}
.CandidateVote_Name {
  @include text(L, 700, $white);
}

.CandidateVoteContainer {
  width: 100%;
  @include flex-center-column;
  gap: 5px;
}

.TabPanel {
  min-height: 300px;
  text-align: left !important;
}

.TabPanelLabel {
  width: 100%;
  text-align: left;
}

.CollegeName {
  font-family: "Inter";
  font-style: normal;
  padding: 15px;
  text-transform: uppercase;
  @include text(L, 700, $black);
}

.ElectionTypeName {
  padding: 15px;
  padding-left: 25px;
  @include text(L, 400, $grey);
}

.CandidateBox {
  margin-bottom: 12px;
}

.TableCell {
  border-bottom: 1px solid #eaecf0;
  font-size: 14px;
  font-weight: 400;
  color: #667085;
  padding: 26px 24px;
}

.SecondTurnNeed {
  border: 1px solid black;
  padding: 25px;
  @include text(XL, 700, $black);
  margin: 35px 15px;
  width: -webkit-fill-available;
}

.HorizontalScrollArea {
  width: 100%;
  @media (max-width: 768px) {
    overflow-x: scroll;
    margin-bottom: 50px;
  }
}
