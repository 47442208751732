@import "../../styles/main";

.TableHeader {
  min-width: 100px;
  font-size: 12px;
  font-weight: 400;
  color: #667085;
  background-color: #f9fafb;
  padding: 13px 24px;
}
