.VoteStatContainer {
	background-color: #ecf0f1 !important;
	color: #34495e;
	width: 200px;
	padding: 10px;
	margin: 0px 5px;

	@media (max-width: 500px) {
		padding: 5px;
	}
}

.VoteInfoContainer {
	padding: 10px;
	margin-right: auto;
	margin-left: auto;
	display: flex;
	width: 400px;
	max-width: 100%;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	color: white !important;
	font-size: 22px;
	margin-bottom: 30px;
	margin-top: 30px;

	@media (max-width: 500px) {
		width: 300px;
		font-size: 16px;
		height: 40px;
	}
}

.MuiFab-root {
	height: 90px !important;
	width: 90px !important;
}

.MuiFab-root.MuiButtonBase-root {
	border-style: solid;
	border-width: 3px;
}
