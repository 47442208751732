@import "../../styles/main";

.CseNavigation {
  @include flex-center;
}

.CseNavigation_container {
  @include flex-center-wrap;
  justify-content: flex-start;
  padding-bottom: 24px;
  max-width: 880px;
  border-bottom: 1px solid $grey-four;
  margin-bottom: 32px;

  @media (max-width: 768px) {
    justify-content: center;
  }
}

.CseNavigation_Link {
  @include flex-center;
  width: fit-content;
  padding: 3px 0;
}
