@import "../styles/main";

.CseTitle {
  @include text("XXL", 400, $black);
}

.CseHeaderName {
  @include text("XXL", 700, $black);
  font-weight: bolder;
}

.CseContainer {
  display: flex;
  justify-content: center;
  padding-top: 25px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 15px;
    padding: 16px 16px;
  }
}

.CseLoginInfoContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.CseLogin {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 85px;
  background: #f5f6f9;
  border-radius: 4px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 15px;
    padding: 16px 16px;
  }
}

.CseLoginInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 26px;
  margin-left: 15px;
  margin-bottom: 15px;
  max-width: 446px;
  background: #f5f6f9;
  border-radius: 4px;
  @media (max-width: 768px) {
    max-width: 100%;
    margin-left: 0px;
    margin-bottom: 0px;
  }
}

.CseLoginHeadText {
  @include text("XL", 400, $black);
  padding-bottom: 2em;
  padding-top: 1em;
}

.CseLoginBold {
  @include text("XL", 600, $black);
}

.CseConnexionButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 24px;
  position: static;
  min-width: 121px;
  min-height: 57px;
  background: #1d529c;
  margin-top: 5em;
}

.CseLoginInfoTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  color: #1d529c;
}

.CseLoginInfoList {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ListWithoutDot {
  list-style-type: none;
}

.ListIcon {
  margin-top: 5px;
  margin-right: 5px;
  object-fit: contain;
}
