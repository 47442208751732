@import "../../styles/main";

.Accordion {
    width: 100%;
}

.Accordion_titleContainer {
    cursor: pointer;
    @include flex-center;
    justify-content: space-between;
    border-radius: 4px;
    min-height: 29px;
}

.Accordion_titleContainer__white {
    background-color: $white;
    padding-bottom: 3px;
    border-bottom: 1px solid $white-two;
}

.Accordion_titleContainer__grey {
    background-color: $grey-three;
}

.Accordion_titleContainer__blue {
    background-color: $blue-three;
}

.Accordion_titleContainer__alwaysClosed {
    cursor: default;
}

.Accordion_item {
    overflow: hidden;
    transition: max-height 0.6s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
    @include flex-center;
    justify-content: flex-start;
}

.Accordion_item__collapsed {
    max-height: 0;
    transition: max-height 0.6s cubic-bezier(0, 1, 0, 1);
}

.PlusContainer {
    position: relative;
}
.PlusContainer_plus {
    &:after,
    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 10px;
        width: 11.25px;
        height: 2px;
        background-color: $blue-two;
    }
    &:before {
        transform: rotate(90deg);
        transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
    }
    &[aria-expanded="true"] {
        &:before {
            transform: rotate(0deg);
            transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
        }
    }
}
