@import "./mixins.scss";

$white: #ffffff;
$white-two: #eaeef3;

$black: #06101f;
$black-two: #0c213e;

$blue: #17427d;
$blue-two: #1d529c;
$blue-three: #d2dceb;

$grey: #58626f;
$grey-two: #8b95a2;
$grey-three: #f5f6f9;
$grey-four: #e2e4e8;

$green: #a5dc86;
