body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.react-datepicker-wrapper {
	width: 100%;
}

.react-datepicker__input-container input {
	width: 100%;
	padding: 10px;
	margin-bottom: 8px;
	box-sizing: border-box;
	border-radius: 2px;
	border: solid 1px #c0c3cb;
	font-family: inherit;
	font-size: 15px;
}

.logo_landing {
	width: 200px;
}

@media print {
	body {
		zoom: 5;
	}
	.hideOnPrint {
		display: none;
	}
	.printableContainer {
		max-width: 100% !important;
	}
}

/** Medium and hight Device **/

@media screen and (min-width: 768px) {
	.Toastify__toast-container {
		min-width: 600px;
		text-align: center;
		font-size: large;
		margin-top: 50px;
		height: 100px
	}
	.Toastify__toast {
		color: black !important;
    	background: #e7e7e7 !important;
		border: 2px solid grey;
		height: 150px;
	}
	.logo_landing {
		min-width: 300px;
	}
}


@media screen and (max-width: 768px) {
	
}
