@import "../../styles/main";

.CreationListFormContainer {
    @include flex-center-wrap;
    align-items: flex-start;
    gap: 180px;
    div {
        flex: 1;
    }
    @include mq("tablet-wide") {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        .title {
            text-align: center;
        }
    }
}

.title {
    text-align: left;
    @include mq("tablet-wide") {
        text-align: center;
    }
}

.CreationListFormContainer_title__invisible {
    color: white;
}

.CreationListFormContainer_Right {
    margin: 0 auto;
}

.CandidatesList {
    margin: 24px 0;
}
.CandidatesList_List {
    @include flex-center-wrap;
    gap: 10px;
}

.AddCandidate_Button {
    margin-bottom: 24px;
}
