@import "../../styles/main";

.CseLayout {
  @include flex-center-column;
  padding: 20px 20px;
  box-sizing: border-box;
  width: 100%;
}

.mainContainer2 {
  @include flex-center-column;
  max-width: 768px;
  width: 100%;
  gap: 20px;
  padding: 0 20px;
}

.titleContainer {
  @include box-sizing(border-box);
  @include flex-center-wrap;
  justify-content: space-between;
  width: 100%;
}

.title {
  @include text(XXL, 400, $black);
}

.buttonWrapper {
  width: fit-content;
  @include box-sizing(border-box);
  @include flex-center-horiz;
}
