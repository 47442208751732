.DeleteButton {
  width: auto !important;
  margin-left: 10px;
  background-color: red !important;
  font-weight: 800;
  padding: 8px;
}

.DeletePersonButton {
  width: auto !important;
  background-color: red !important;
  font-weight: 800;
}

.AdminCard {
  width: 100%;
  background-color: #f5f6f9;
  padding: 28px 23px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 15px;
    padding: 20px 5px;
  }
}

.AdminCardContent {
  margin-bottom: 5px;
}
