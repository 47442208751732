@import "../../styles/main";

.CseVoterLanding_Title {
  @include text("XL", 400, $black);
}

.CseVoterLanding_CollegeName {
  @include text("XL", 700, $black);
}

.CseVoterLanding_Time {
  @include text("XXL", 700, $blue-two);
}

.CseVoterLanding_Button {
  width: auto;
  height: 57px;
  margin-top: 20px;
  width: 100%;
}

.VoteContainer {
  border: 1px solid $grey-four;
  @include flex-center;
  justify-content: flex-start;
}

.VoteContainer_Logo {
  padding: 25px 15px;
  object-fit: contain;
}

.VoteContainer_Time {
  @include text("L", 500, $blue);
}

.VoteContainer_Title {
  @include text("L", 400, $black);
}
