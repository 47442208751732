@mixin flex-column {
    display: flex;
    flex-direction: column;
}

@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-center-wrap {
    @include flex-center;
    flex-wrap: wrap;
}

@mixin flex-center-column {
    @include flex-center;
    flex-direction: column;
}

@mixin flex-center-vert {
    display: flex;
    align-items: center;
}

@mixin flex-center-horiz {
    display: flex;
    justify-content: center;
}

// media query mixins

$breakpoints: (
    "phone": 400px,
    "phone-wide": 480px,
    "phablet": 560px,
    "tablet-small": 640px,
    "tablet": 768px,
    "tablet-wide": 1024px,
    "desktop": 1248px,
    "desktop-wide": 1440px,
);
@mixin mq($width, $type: max) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == max {
            $width: $width - 1px;
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}

// font sizes mixins

@mixin font-size($font-size) {
    @if $font-size==XXL {
        font-size: 25px;
        line-height: 24px;
    }
    @if $font-size==XL {
        font-size: 20px;
        line-height: 21px;
    }
    @if $font-size==L {
        font-size: 16px;
        line-height: 17px;
    }
    @if $font-size==M {
        font-size: 14px;
        line-height: 14px;
    }
    @if $font-size==S {
        font-size: 12px;
        line-height: 11px;
    }
}

@mixin responsive-ratio($x, $y, $pseudo: false) {
    $padding: unquote(($y / $x) * 100 + "%");
    @if $pseudo {
        &:before {
            @include pseudo($pos: relative);
            width: 100%;
            padding-top: $padding;
        }
    } @else {
        padding-top: $padding;
    }
}

@mixin font-family() {
    font-family: inherit;
    font-style: normal;
}

@mixin text($font-size, $weight: 400, $color: false) {
    @include font-family();
    @include font-size($font-size);
    @if $weight {
        font-weight: $weight;
    }
    @if $color {
        color: $color;
    }
}

$z-indexes: ("modal", "sidebar", “header”);

@function z($name) {
    @if index($z-indexes, $name) {
        @return (length($z-indexes) - index($z-indexes, $name))+1;
    } @else {
        @warn 'There is no item "#{$name}" in this list; choose one of: #{$z-indexes}';
        @return null;
    }
}

@mixin box-sizing($box-model) {
    -webkit-box-sizing: $box-model; // Safari <= 5
    -moz-box-sizing: $box-model; // Firefox <= 19
    box-sizing: $box-model;
}
