@import "../../styles/main";

.title {
  @include text(XXL, 400, $black);
}

.buttonContainer {
  @include flex-center;
  justify-content: space-between;
  gap: 6px;
}

.form {
  @include flex-column;
  justify-content: flex-start;
  gap: 5px;
}

.buttonContainer__isEdit {
  justify-content: center;
}

.button__isEdit {
  width: 150px;
}

.successMessage {
  @include text(L, 600, $green);
}

.fieldsetForm {
  border: none;
}
