@import "../../styles/main";

.Link {
    @include text(L, 400, $blue-two);
    border: none;
    background: none;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.Link__selected {
    @include text(L, 600, $black);
    cursor: default;
    pointer-events: none;
    &:hover {
        text-decoration: none;
    }
}

.Link__streamlined {
    font-size: unset;
    font-weight: unset;
    color: unset;
    border: none;
    background: none;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}
